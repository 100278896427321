import React from 'react';
import {
  QueryResult,
} from 'react-apollo';
import {
  TradeDirection,
  TradeFlow,
} from '../../graphQL/graphQLTypes';
import {
  TreeMapInput,
  TreeMapType,
} from '../../graphQL/tree/graphQLTypes';
import HighlightDropdown, {
  IProps as IHighlightDropdownProps,
} from '../../sharedComponents/NewHighlightDropdown';
import {
  failIfValidOrNonExhaustive,
  INewDropdownOption,
} from '../../Utils';
import {
  FetchedLocationDatum,
  FetchedProductDatum,
} from './graphqlTypes';
import transformLocation from './transformLocation';
import transformProduct from './transformProduct';

export interface Variables {
  treeMapInput: TreeMapInput;
}

export const innerQuery = `
  dropdown: treeMap(input: $treeMapInput) {
    ... on TreeMapProduct {
      product {
        id
        shortName
        code
        topLevelParent {
          id
        }
        level
      }
    }
    ... on TreeMapLocation {
      location {
        id
        shortName
        longName
        code
        topLevelParent {
          id
        }
        level
      }
    }

    exportValue
    importValue
  }
`;

interface ISuccessResponse {
  dropdown: FetchedLocationDatum[] | FetchedProductDatum[];
}

type IRenderPropInput = {
  selectedCategories: string[]
  tradeDirection: TradeDirection
  tradeFlow: TradeFlow
  highlighted: string | undefined
  onChange: (option: INewDropdownOption | null) => void,
} & Pick<IHighlightDropdownProps, 'DropdownComponent' | 'DropdownContainerComponent'>;

export const getRenderProp =
  (input: IRenderPropInput) =>
    (queryResult: QueryResult<ISuccessResponse, Variables>): React.ReactNode => {

  const {data, error, loading, variables} = queryResult;
  const {
    highlighted: selected, selectedCategories, tradeDirection, tradeFlow, onChange,
    DropdownComponent, DropdownContainerComponent,
  } = input;
  let options: INewDropdownOption[];
  if (loading ) {
    options = [];
  } else if (error) {
    options = [];
  } else if (data !== undefined) {
    const {dropdown} = data;
    const {treeMapInput: {type, productClass}} = variables;
    if (type === TreeMapType.CPY_C ||
        type === TreeMapType.CCPY_CC) {
      options = transformProduct({
        fetchResult: dropdown as FetchedProductDatum[],
        selectedCategories, productClass,
        tradeDirection, tradeFlow,
      });
    } else if (type === TreeMapType.CCY_C ||
                type === TreeMapType.CCPY_CP ||
                type === TreeMapType.CPY_P)  {
      options = transformLocation({
        fetchResult: dropdown as FetchedLocationDatum[],
        selectedCategories, tradeDirection, tradeFlow,
      });
    } else {
      failIfValidOrNonExhaustive(type, 'Invalid tree map type' + type);
      // The following lines will never be executed:
      options = [];
    }
  } else {
    options = [];
  }
  return (
    <HighlightDropdown
      label={__lexiconText('applicationWide.highlightDropdown.mainLabel')}
      tooltipText={__lexiconText('applicationWide.highlightDropdown.tooltipText')}
      options={options}
      value={selected}
      onChange={onChange}
      isClearable={true}
      DropdownComponent={DropdownComponent}
      DropdownContainerComponent={DropdownContainerComponent}
    />
  );
};
