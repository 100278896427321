import React from 'react';
import {
  QueryResult,
} from 'react-apollo';
import {
  TradeDirection,
  TradeFlow,
} from '../../graphQL/graphQLTypes';
import {
  TreeMapInput,
} from '../../graphQL/tree/graphQLTypes';
import useLastSuccesfulFetch from '../newChart/useLastSuccesfulFetch';
import DetailOverlayContainer from './DetailOverlayContainer';
import {
  FetchedLocationDatum,
  FetchedProductDatum,
} from './graphQLTypes';
import transform from './transform';

export const innerQuery = `
  detailOverlay: treeMap(input: $treeMapInput) {
    ... on TreeMapProduct {
      product {
        id
        code
        longName
        pci(year: $year)
        topLevelParent {
          id
        }
        level
      }
      rca
      distance
    }

    ... on TreeMapLocation {
      location {
        id
        longName
        topLevelParent {
          id
        }

      }
    }

    exportValue
    importValue
  }
`;

export interface Variables {
  treeMapInput: TreeMapInput;
  year: number;
}

export interface ISuccessResponse {
  detailOverlay: FetchedLocationDatum[] | FetchedProductDatum[];
}

interface IRenderPropInput {
  hideOverlay: () => void;
  detailed: string | undefined;
  tradeDirection: TradeDirection;
  tradeFlow: TradeFlow;
  year: number;
}

type Result = QueryResult<ISuccessResponse, Variables>;

interface IProps {
  renderPropInput: IRenderPropInput;
  queryResult: Result;
}

const combineNewIntoOldRenderPropInput =
  (oldInput: IRenderPropInput, newInput: IRenderPropInput): IRenderPropInput => {

  const {
    hideOverlay: _unused1,
    detailed: _unused2,
    ...restOfOldInput
  } = oldInput;
  const out: IRenderPropInput = {
    ...restOfOldInput,
    hideOverlay: newInput.hideOverlay,
    detailed: newInput.detailed,
  };
  return out;
};

const isFetchedDataEmpty = ({detailOverlay}: ISuccessResponse) => (detailOverlay.length === 0);

const Wrapper = (props: IProps) => {
  const lastSuccesfulFetch = useLastSuccesfulFetch<ISuccessResponse, Variables, IRenderPropInput>({
    isFetchedDataEmpty,
    inputs: {
      queryResult: props.queryResult,
      extraInputs: props.renderPropInput,
    },
  });

  const {
    renderPropInput,
    queryResult: {loading, error, data, variables},
  } = props;
  let output: React.ReactElement<any> | null;
  if (loading === true) {
    if (lastSuccesfulFetch === undefined) {
      output = null;
    } else {
      const combinedRenderPropInput = combineNewIntoOldRenderPropInput(
        lastSuccesfulFetch.extraInputs, renderPropInput,
      );
      const {tradeDirection, tradeFlow, hideOverlay, detailed} = combinedRenderPropInput;
      const transformed = transform({
        fetchResult: lastSuccesfulFetch.data.detailOverlay,
        type: lastSuccesfulFetch.variables.treeMapInput.type,
        productClass: lastSuccesfulFetch.variables.treeMapInput.productClass,
        year: lastSuccesfulFetch.extraInputs.year,
        tradeDirection, tradeFlow,
      });
      output = (
        <DetailOverlayContainer
          tooltipMap={transformed}
          detailed={detailed}
          hideOverlay={hideOverlay}
        />
      );
    }
  } else if (error !== undefined) {
    output = null;
    console.error(error);
  } else if (data !== undefined) {
    const transformed = transform({
      fetchResult: data.detailOverlay,
      type: variables.treeMapInput.type,
      productClass: variables.treeMapInput.productClass,
      year: renderPropInput.year,
      tradeDirection: renderPropInput.tradeDirection,
      tradeFlow: renderPropInput.tradeFlow,
    });
    output = (
      <DetailOverlayContainer
        tooltipMap={transformed}
        detailed={renderPropInput.detailed}
        hideOverlay={renderPropInput.hideOverlay}
      />
    );
  } else {
    output = null;
  }
  return output;
};

export const getRenderProp = (input: IRenderPropInput) => (queryResult: Result) => (
  <Wrapper renderPropInput={input} queryResult={queryResult}/>
);
