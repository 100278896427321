import {
  TradeDirection,
  TradeFlow,
} from '../../graphQL/graphQLTypes';
import {
  failIfValidOrNonExhaustive,
} from '../../Utils';

const getTradeLabel = (tradeDirection: TradeDirection, tradeFlow: TradeFlow): string => {
  if (tradeDirection === TradeDirection.export) {
    if (tradeFlow === TradeFlow.Gross) {
      return __lexiconText('applicationWide.monetaryValue.grossExport');
    } else if (tradeFlow === TradeFlow.Net) {
      return __lexiconText('applicationWide.monetaryValue.netExport');
    } else {
      failIfValidOrNonExhaustive(tradeFlow, 'Invalid trade flow' + tradeFlow);
      // The following lines will never be executed:
      return '';
    }
  } else if (tradeDirection === TradeDirection.import) {
    if (tradeFlow === TradeFlow.Gross) {
      return __lexiconText('applicationWide.monetaryValue.grossImport');
    } else if (tradeFlow === TradeFlow.Net) {
      return __lexiconText('applicationWide.monetaryValue.netImport');
    } else {
      failIfValidOrNonExhaustive(tradeFlow, 'Invalid trade flow' + tradeFlow);
      // The following lines will never be executed:
      return '';
    }
  } else {
    failIfValidOrNonExhaustive(tradeDirection, 'Invalid trade direction' + tradeDirection);
    // The following lines will never be executed:
    return '';
  }
};

export default getTradeLabel;
