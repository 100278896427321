import gql from 'graphql-tag';
import React from 'react';
import {
  QueryResult,
} from 'react-apollo';
import {
  ProductLevel,
  TradeDirection,
  TradeFlow,
} from '../../graphQL/graphQLTypes';
import {
  TreeMapInput,
  TreeMapType,
} from '../../graphQL/tree/graphQLTypes';
import ErrorOverlay from '../../sharedComponents/GraphError';
import Loading from '../../sharedComponents/GraphLoading';
import {
  failIfValidOrNonExhaustive,
} from '../../Utils';
import Chart, {
} from './Chart';
import {
  NumCellsTier,
} from './domIndependentDrawUtils';
import {
  FetchedLocationDatum,
  FetchedProductDatum,
} from './graphQLTypes';
import transform from './transform';
import useLastSuccesfulFetch from './useLastSuccesfulFetch';

export interface Variables {
  treeMapInput: TreeMapInput;
}

export const innerQuery = `
  chart: treeMap(input: $treeMapInput) {
    ... on TreeMapProduct {
      product {
        id
        shortName
        type
        code
        topLevelParent {
          id
          shortName
        }
      }
    }

    ...on TreeMapLocation {
      location {
        id
        shortName
        topLevelParent {
          id
        }
      }
    }

    exportValue
    importValue
  }
`;

export const outerQuery = gql`
  query TreeMapChart($treeMapInput: TreeMapInput!) {
    ${innerQuery}
  }
`;

export interface ISuccessResponse {
  chart: FetchedProductDatum[] | FetchedLocationDatum[];
}

export interface IRenderPropInput {
  width: number;
  height: number;
  selectedCategories: string[];
  tradeDirection: TradeDirection;
  tradeFlow: TradeFlow;
  highlighted: string | undefined;
  onCellClick: (id: string) => void;
  onMouseOverCell: (id: string) => void;
  onMouseLeaveChart: () => void;
}

type Result = QueryResult<ISuccessResponse, Variables>;

const determineTreeMapNumCellsTier = (type: TreeMapType, productLevel: ProductLevel | null): NumCellsTier => {
  let numCellsTier: NumCellsTier;
  if (type === TreeMapType.CPY_C || type === TreeMapType.CCPY_CC) {

    if (productLevel === ProductLevel.section ||
          productLevel === ProductLevel.twoDigit ||
          productLevel === ProductLevel.fourDigit) {
      numCellsTier = NumCellsTier.Small;
    } else if (productLevel === ProductLevel.sixDigit) {
      numCellsTier = NumCellsTier.Large;
    } else if (productLevel === null) {
      throw new Error('Product level cannot be null for CPY_C and CCPY_CC');
    } else {
      failIfValidOrNonExhaustive(productLevel, 'Invalid product level ' + productLevel);
      // The following lines will never be executed:
      numCellsTier = NumCellsTier.Small;
    }
  } else if (type === TreeMapType.CPY_P ||
              type === TreeMapType.CCY_C ||
              type === TreeMapType.CCPY_CP) {
    numCellsTier = NumCellsTier.Small;
  } else {
    failIfValidOrNonExhaustive(type, 'Invalid tree map type' + type);
    // The following lines will never be executed:
    numCellsTier = NumCellsTier.Small;
  }
  return numCellsTier;
};
interface IProps {
  renderPropInput: IRenderPropInput;
  queryResult: Result;
}

const isFetchedDataEmpty = ({chart}: ISuccessResponse) => (chart.length === 0);

const combineNewIntoOldRenderPropInputs =
  (oldInput: IRenderPropInput, newInput: IRenderPropInput): IRenderPropInput => {

  const {
    width: _unused1,
    height: _unused2,
    onCellClick: _unused4,
    onMouseOverCell: _unused5,
    onMouseLeaveChart: _unsed6,
    highlighted: _unused7,
    ...restOfOldInput
  } = oldInput;
  return {
    ...restOfOldInput,
    width: newInput.width,
    height: newInput.height,
    onCellClick: newInput.onCellClick,
    onMouseOverCell: newInput.onMouseOverCell,
    onMouseLeaveChart: newInput.onMouseLeaveChart,
    highlighted: newInput.highlighted,
  };
};

const ChartContainer = (props: IProps) => {

  const lastSuccesfulFetch = useLastSuccesfulFetch<ISuccessResponse, Variables, IRenderPropInput>({
    isFetchedDataEmpty,
    inputs: {
      extraInputs: props.renderPropInput,
      queryResult: props.queryResult,
    },
  });
  const {renderPropInput, queryResult} = props;

  let output: React.ReactElement<any> | null;
  if (queryResult.loading === true) {
    if (lastSuccesfulFetch !== undefined) {
      const combinedRenderPropInput = combineNewIntoOldRenderPropInputs(
        lastSuccesfulFetch.extraInputs, props.renderPropInput,
      );
      const {transformed} = transform({
        fetchedData: lastSuccesfulFetch.data.chart,
        variables: lastSuccesfulFetch.variables,
        otherInputs: {
          width: combinedRenderPropInput.width,
          height: combinedRenderPropInput.height,
          selectedCategories: combinedRenderPropInput.selectedCategories,
          tradeDirection: combinedRenderPropInput.tradeDirection,
          tradeFlow: combinedRenderPropInput.tradeFlow,
        },
      });

      const numCellsTier = determineTreeMapNumCellsTier(
        lastSuccesfulFetch.variables.treeMapInput.type,
        lastSuccesfulFetch.variables.treeMapInput.productLevel,
      );
      output = (
        <>
          <Chart
            highlighted={renderPropInput.highlighted}
            cells={transformed}
            numCellsTier={numCellsTier}
            chartContainerHeight={combinedRenderPropInput.height}
            chartContainerWidth={combinedRenderPropInput.width}
            onCellClick={combinedRenderPropInput.onCellClick}
            onMouseOverCell={combinedRenderPropInput.onMouseOverCell}
            onMouseLeaveChart={combinedRenderPropInput.onMouseLeaveChart}
          />
          <Loading/>
        </>
      );
    } else {
      output = (<Loading/>);
    }
  } else if (queryResult.error) {
    output = (
      <ErrorOverlay message={__lexiconText('error.fetchData')}/>
    );
  } else if (queryResult.data) {

    const {transformed} = transform({
      fetchedData: queryResult.data.chart,
      variables: queryResult.variables,
      otherInputs: {
        width: renderPropInput.width,
        height: renderPropInput.height,
        selectedCategories: renderPropInput.selectedCategories,
        tradeDirection: renderPropInput.tradeDirection,
        tradeFlow: renderPropInput.tradeFlow,
      },
    });

    if (transformed.length === 0) {
      output = (
        <ErrorOverlay message={__lexiconText('error.noData')}/>
      );
    } else {
      const numCellsTier = determineTreeMapNumCellsTier(
        queryResult.variables.treeMapInput.type,
        queryResult.variables.treeMapInput.productLevel,
      );
      output = (
        <Chart
          highlighted={renderPropInput.highlighted}
          cells={transformed}
          numCellsTier={numCellsTier}
          chartContainerHeight={renderPropInput.height}
          chartContainerWidth={renderPropInput.width}
          onCellClick={renderPropInput.onCellClick}
          onMouseOverCell={renderPropInput.onMouseOverCell}
          onMouseLeaveChart={renderPropInput.onMouseLeaveChart}
        />
      );
    }
  } else {
    output = null;
  }
  return output;
};

export const getRenderProp = (input: IRenderPropInput) => (queryResult: Result) => (
  <ChartContainer renderPropInput={input} queryResult={queryResult}/>
);
