import {
  ProductClass,
  TradeDirection,
  TradeFlow,
} from '../../graphQL/graphQLTypes';
import {
  TreeMapType,
} from '../../graphQL/tree/graphQLTypes';
import {
  failIfValidOrNonExhaustive,
} from '../../Utils';
import {
  FetchedLocationDatum,
  FetchedProductDatum,
} from './graphQLTypes';
import {
  ISuccessResponse,
} from './index';
import {
  IOverlayDatum,
} from './otherTypes';
import transformLocations from './transformLocations';
import transformProducts from './transformProducts';

interface IInput {
  fetchResult: ISuccessResponse['detailOverlay'];
  tradeDirection: TradeDirection;
  tradeFlow: TradeFlow;
  type: TreeMapType;
  productClass: ProductClass | null;
  year: number;
}
const transform = (input: IInput): Map<string, IOverlayDatum> => {
  const {
    fetchResult, tradeDirection, tradeFlow, type, productClass, year,
  } = input;

  if (type === TreeMapType.CPY_C ||
        type === TreeMapType.CCPY_CC) {
    const addExtraProductDetails = (type === TreeMapType.CPY_C);
    if (productClass === null) {
      throw new Error('Product class cannot be null in CPY_C and CCPY_CC');
    }
    return transformProducts({
      fetchResult: fetchResult as FetchedProductDatum[],
      tradeDirection, tradeFlow, productClass,
      year, addExtraProductDetails,
    });
  } else if (type === TreeMapType.CPY_P || type === TreeMapType.CCY_C ||
              type === TreeMapType.CCPY_CP) {
    return transformLocations({
      fetchResult: fetchResult as FetchedLocationDatum[],
      tradeDirection, tradeFlow, year,
    });
  } else {
    failIfValidOrNonExhaustive(type, 'Invalid tree map type' + type);
    // The following lines will never be executed:
    return new Map();
  }
};

export default transform;
