import gql from 'graphql-tag';
import React from 'react';
import {
  Query, QueryResult,
} from 'react-apollo';
import {
  AllLocationsInput,
  AllProductsInput,
  Location,
  Product,
  ProductClass,
} from '../graphQL/graphQLTypes';
import Loading from '../sharedComponents/GraphLoading';
import { SharePlaceholder, TutorialModalProps } from '../sharedComponents/newGraphShare';
import { SetActivePanelProps } from '../viz/Utils';
import { ChartContainer } from '../viz/VizGrid';
import TreeDynamic from './Dynamic';
// `Static` means that the component doesn't depend on the initial query to
// load all products and locations info.
import TreeStatic from './Static';

interface Variables {
  treeIndexLocationsInput: AllLocationsInput;
  treeIndexHSProductsInput: AllProductsInput;
  treeIndexSITCProductsInput: AllProductsInput;
}

const variables: Variables = {
  treeIndexLocationsInput: {
    level: null,
  },
  treeIndexHSProductsInput: {
    productClass: ProductClass.HS,
    level: null,
  },
  treeIndexSITCProductsInput: {
    productClass: ProductClass.SITC,
    level: null,
  },
};

const indexQuery = gql`
  fragment productFields on Product {
    id
    shortName
    level
    topLevelParent {
      id
      type
    }
  }
  query TreeIndex(
    $treeIndexLocationsInput: AllLocationsInput!,
    $treeIndexHSProductsInput: AllProductsInput!,
    $treeIndexSITCProductsInput: AllProductsInput!
  ) {
    treeIndexLocations: allLocations(input: $treeIndexLocationsInput) {
      id
      shortName
      level
      isDataTrustworthy
      hasReportedServicesInAnyYear
      hasReportedServicesLastYear
    }

    treeIndexHSProducts: allProducts(input: $treeIndexHSProductsInput) {
      ... productFields
    }

    treeIndexSITCProducts: allProducts(input: $treeIndexSITCProductsInput) {
      ... productFields
    }
  }
`;

export interface FetchedLocationDatum {
  id: Location['id'];
  shortName: Location['shortName'];
  level: Location['level'];
  isDataTrustworthy: Location['isDataTrustworthy'];
  hasReportedServicesInAnyYear: Location['hasReportedServicesInAnyYear'];
  hasReportedServicesLastYear: Location['hasReportedServicesLastYear'];
}

export interface FetchedProductDatum {
  id: Product['id'];
  shortName: Product['shortName'];
  level: Product['level'];
  topLevelParent: {
    type: Product['topLevelParent']['type'],
  };
}

export interface ISuccessResponse {
  treeIndexLocations: FetchedLocationDatum[];
  treeIndexHSProducts: FetchedProductDatum[];
  treeIndexSITCProducts: FetchedProductDatum[];
}

type Props = SetActivePanelProps & TutorialModalProps;

const Tree = (props: Props) => {
  const {activePanel, setActivePanel, setIsTutorialModalOpen, isTutorialModalOpen} = props;

  const renderProp =
      ({loading, error, data}: QueryResult<ISuccessResponse, Variables>) => {

    let output: React.ReactElement<any> | null;
    if (loading === true) {
      output = (
        <>
          <ChartContainer>
            <Loading/>
          </ChartContainer>
          <SharePlaceholder />
        </>
      );
    } else if (error !== undefined) {
      console.error(error);
      output = (
        <>
          <ChartContainer>
            <Loading/>
          </ChartContainer>
          <SharePlaceholder />
        </>
      );
    } else if (data !== undefined) {
      const {treeIndexHSProducts, treeIndexLocations, treeIndexSITCProducts} = data;
      output = (
        <>
          <TreeDynamic
            treeIndexLocations={treeIndexLocations}
            treeIndexHSProducts={treeIndexHSProducts}
            treeIndexSITCProducts={treeIndexSITCProducts}
            isTutorialModalOpen={isTutorialModalOpen}
            setIsTutorialModalOpen={setIsTutorialModalOpen}
          />
        </>
      );
    } else {
      output = null;
    }
    return output;
  };
  return (
    <>
      <TreeStatic
        activePanel={activePanel}
        setActivePanel={setActivePanel}
      />
      <Query
        query={indexQuery}
        variables={variables}
        children={renderProp}
      />
    </>
  );
};

export default Tree;
