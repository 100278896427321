import {
  ProductClass,
  TradeDirection,
  TradeFlow,
} from '../../graphQL/graphQLTypes';
import {
  getProductSearchString,
  INewDropdownOption,
  newGetProductDropdownLabel,
} from '../../Utils';
import {
  computeGrossNetTradeValues,
  filterByMonetaryValues,
  filterBySelectedCategories,
} from '../newChart/transformUtils';
import {
  FetchedProductDatum,
} from './graphqlTypes';

interface ITransformInput {
  fetchResult: FetchedProductDatum[];
  selectedCategories: string[];
  productClass: ProductClass | null;
  tradeDirection: TradeDirection;
  tradeFlow: TradeFlow;
}

const transform = (input: ITransformInput): INewDropdownOption[] => {
  const {
    tradeDirection, tradeFlow, fetchResult, selectedCategories, productClass,
  } = input;
  const withComputedTradeValues = computeGrossNetTradeValues(fetchResult, tradeDirection, tradeFlow);
  const filtered = filterByMonetaryValues(withComputedTradeValues);
  const transformed = filtered.map(elem => {
    const {
      product: {
        topLevelParent: {id: topLevelParentId},
        ...restOfProduct
      },
      ...restOfElem
    } = elem;
    return {
      ...restOfElem,
      ...restOfProduct,
      topLevelParentId,
    };
  });
  const filteredByCategories = filterBySelectedCategories(transformed, selectedCategories);
  const sortedByCode = filteredByCategories.sort(
    (a, b) => (a.code <= b.code) ? -1 : 1,
  );
  if (productClass === null) {
    throw new Error('Product class cannot be null when in product mode');
  }
  const result: INewDropdownOption[] = sortedByCode.map(({shortName, code, level, id}) => {
    const label = newGetProductDropdownLabel(
     shortName, productClass, code, level,
    );
    const searchString = getProductSearchString(
      shortName, productClass, code,
    );
    return {
      value: id,
      label,
      searchString,
    };
  });
  return result;
};

export default transform;
