import {
  generateStringProductId,
} from '../graphQL/dataFetchers/Utils';
import {
  generateStringLocationId,
} from '../graphQL/dataFetchers/Utils';
import {
  ProductClass,
} from '../graphQL/graphQLTypes';
import {
  TreeMapInput,
  TreeMapType,
} from '../graphQL/tree/graphQLTypes';
import {
  failIfValidOrNonExhaustive,
  Target,
} from '../Utils';

export enum ErrorCode {
  PickCountryOrProduct = 'PicCountryOrProduct',
  PickCountryOrPartner = 'PictCountryOrPartner',
}

type PartialTreeMapInput = Pick<TreeMapInput, 'type' | 'location' | 'product' | 'partner'>;

type IResult = {
  isValid: true
  info: PartialTreeMapInput;
} | {
  isValid: false
  errorCode: ErrorCode,
};

interface IInput {
  country: number | undefined;
  product: number | undefined;
  target: Target;
  partner: number | undefined;
  productClass: ProductClass;
}

export default (input: IInput): IResult => {
  const {
    country, product, partner, target, productClass,
  } = input;

  let output: IResult;
  if (target === Target.Product) {
    if (country !== undefined && product === undefined) {
      output = {
        isValid: true,
        info: {
          type: TreeMapType.CPY_C,
          location: generateStringLocationId(country),
          product: null,
          partner: null,
        },
      };
    } else if (country === undefined && product !== undefined) {
      output = {
        isValid: true,
        info: {
          type: TreeMapType.CPY_P,
          location: null,
          product: generateStringProductId({productClass, id: product}),
          partner: null,
        },
      };
    } else if (country !== undefined && product !== undefined) {
      output = {
        isValid: true,
        info: {
          type: TreeMapType.CCPY_CP,
          location: generateStringLocationId(country),
          product: generateStringProductId({productClass, id: product}),
          partner: null,
        },
      };
    } else {
      output = {
        isValid: false,
        errorCode: ErrorCode.PickCountryOrProduct,
      };
    }
  } else if (target === Target.Partner) {
    if (country !== undefined && partner !== undefined) {
      output = {
        isValid: true,
        info: {
          type: TreeMapType.CCPY_CC,
          location: generateStringLocationId(country),
          product: null,
          partner: generateStringLocationId(partner),
        },
      };
    } else if (country !== undefined && partner === undefined) {
      output = {
        isValid: true,
        info: {
          type: TreeMapType.CCY_C,
          location: generateStringLocationId(country),
          product: null,
          partner: null,
        },
      };
    } else {
      output = {
        isValid: false,
        errorCode: ErrorCode.PickCountryOrPartner,
      };
    }
  } else {
    failIfValidOrNonExhaustive(target, 'Invalid target ' + target);
    // These lines will never be executed:
    output = null as any;
  }
  return output;
};
