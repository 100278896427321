import React from 'react';
import {
  QueryResult,
} from 'react-apollo';
import {
  TradeDirection,
  TradeFlow,
} from '../../graphQL/graphQLTypes';
import {
  TreeMapInput,
} from '../../graphQL/tree/graphQLTypes';
import {
  FetchedLocationDatum,
  FetchedProductDatum,
} from './graphQLTypes';
import GraphTotal from './GraphTotal';
import transform from './transform';

export const innerQuery = `
  graphTotal: treeMap(input: $treeMapInput) {
    ... on TreeMapProduct {
      product {
        id
        topLevelParent {
          id
        }
      }
    }

    ... on TreeMapLocation {
      location {
        id
        topLevelParent {
          id
        }
      }
    }
    exportValue
    importValue
  }
`;

export interface Variables {
  treeMapInput: TreeMapInput;
}

export interface ISuccessResponse {
  graphTotal: FetchedLocationDatum[] | FetchedProductDatum[];
}

type Result = QueryResult<ISuccessResponse, Variables>;

interface IRenderPropInput {
  selectedCategories: string[];
  hiddenCategories: string[];
  tradeDirection: TradeDirection;
  tradeFlow: TradeFlow;
}

interface Props {
  renderPropInput: IRenderPropInput;
  queryResult: Result;
}

const Wrapper = (props: Props) => {
  const {
    renderPropInput: {selectedCategories, tradeDirection, tradeFlow, hiddenCategories},
    queryResult: {loading, error, data, variables},
  } = props;

  let output: React.ReactElement<any> | null;
  if (loading === true) {
    output = null;
  } else if (error !== undefined) {
    output = null;
    console.error(error);
  } else if (data !== undefined) {
    const {graphTotal} = data;
    const {numerator, denominator, totalGoods, totalServices} = transform({
      fetchResult: graphTotal,
      variables,
      otherInputs: {
        selectedCategories, tradeDirection, tradeFlow,
      },
    });

    output = (
      <GraphTotal
        numerator={numerator}
        denominator={denominator}
        totalGoods={totalGoods}
        totalServices={totalServices}
        hiddenCategories={hiddenCategories}
        selectedCategories={selectedCategories}
      />
    );
  } else {
    output = null;
  }
  return output;
};

export const getRenderProp =
  (input: IRenderPropInput) =>
    (queryResult: Result) => (
      <Wrapper renderPropInput={input} queryResult={queryResult}/>
    );
