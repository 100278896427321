import React from 'react';
import {
  QueryResult,
} from 'react-apollo';
import {
  TradeDirection,
  TradeFlow,
} from '../../graphQL/graphQLTypes';
import {
  TreeMapInput,
  TreeMapType,
} from '../../graphQL/tree/graphQLTypes';
import {
  failIfValidOrNonExhaustive,
} from '../../Utils';
import {
  FetchedLocationDatum,
  FetchedProductDatum,
} from './graphQLTypes';
import {
  ITooltipDatum,
} from './otherTypes';
import TooltipsContainer from './TooltipsContainer';
import transformLocations from './transformLocations';
import transformProducts from './transformProducts';

export interface Variables {
  treeMapInput: TreeMapInput;
}

export const innerQuery = `
  hoverTooltips: treeMap(input: $treeMapInput) {
    ... on TreeMapProduct {
      product {
        id
        code
        shortName
        topLevelParent {
          id
        }
        type
        level
      }
    }

    ... on TreeMapLocation {
      location {
        id
        shortName
        topLevelParent {
          id
        }

      }
    }


    exportValue
    importValue
  }
`;

interface IRenderPropInput {
  width: number;
  height: number;
  selectedCategories: string[];
  tradeDirection: TradeDirection;
  tradeFlow: TradeFlow;
  hovered: string | undefined;
  highlighted: string | undefined;
  clearHighlightTooltip: () => void;
}

interface ISuccessResponse {
  hoverTooltips: FetchedLocationDatum[] | FetchedProductDatum[];
}

type Result = QueryResult<ISuccessResponse, Variables>;

interface IProps {
  renderPropInput: IRenderPropInput;
  queryResult: Result;
}

const HoverTooltips = (props: IProps) => {
  const {
    renderPropInput: {
      width, height, selectedCategories, tradeDirection, tradeFlow,
      hovered, highlighted, clearHighlightTooltip,
    },
    queryResult: {loading, error, data, variables},
  } = props;

  let output: React.ReactElement<any> | null;
  if (loading === true) {
    output = null;
  } else if (error !== undefined) {
    console.error(error);
    output = null;
  } else if (data !== undefined) {
    const {hoverTooltips} = data;
    const {treeMapInput: {type, productClass}} = variables;
    let transformed: Map<string, ITooltipDatum>;
    if (type === TreeMapType.CPY_C || type === TreeMapType.CCPY_CC) {
      if (productClass === null) {
        throw new Error('Product class cannot be null in CPY_C or CCPY_CC');
      }
      transformed = transformProducts({
        fetchResult: hoverTooltips as FetchedProductDatum[],
        width, height, selectedCategories,
        tradeDirection, tradeFlow,
        productClass,
      });
    } else if (type === TreeMapType.CCY_C ||
                type === TreeMapType.CCPY_CP ||
                type === TreeMapType.CPY_P) {
      transformed = transformLocations({
        fetchResult: hoverTooltips as FetchedLocationDatum[],
        width, height, selectedCategories,
        tradeDirection, tradeFlow,
      });
    } else {
      failIfValidOrNonExhaustive(type, 'Invalid tree map type' + type);
      // The following lines will never be executed:
      transformed = undefined as any;
    }

    output = (
      <TooltipsContainer
        hovered={hovered}
        highlighted={highlighted}
        tooltipMap={transformed}
        clearHighlightTooltip={clearHighlightTooltip}
      />
    );
  } else {
    output = null;
  }
  return output;
};

export const getRenderProp =
  (input: IRenderPropInput) =>
    (queryResult: Result) => (
      <HoverTooltips renderPropInput={input} queryResult={queryResult}/>
    );
