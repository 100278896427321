import gql from 'graphql-tag';
import React from 'react';
import {
  Query,
  QueryResult,
} from 'react-apollo';
import {
  ProductClass,
  ProductLevel,
} from '../../graphQL/graphQLTypes';
import {
  AllProductsInput,
} from '../../graphQL/graphQLTypes';
import {
  FetchedProductDatum,
} from './graphQLTypes';
import Selector from './ProductCategorySelector';
import {
  SelectorContainer,
} from './sharedStyling';

export interface Variables {
  allProductsInput: AllProductsInput;
}

export const compiledQuery = gql`
  query ProductCategorySelector($allProductsInput: AllProductsInput!) {
    productCategorySelector: allProducts(input: $allProductsInput) {
      id
      type
      shortName
    }
  }
`;

interface SuccessResponse {
  productCategorySelector: FetchedProductDatum[];
}

export type Result = QueryResult<SuccessResponse, Variables>;

interface RenderPropInput {
  selectedCategories: string[];
  setSelected: (selectedCategories: string[]) => void;
  isServicesEnabled: boolean;
  isServicesNotAvailableForAllYears: boolean;
  hasServicesNotAvailableForSomeYearsTooltipBeenShown: boolean;
}

const getRenderProp = (input: RenderPropInput) => (result: Result) => {
  const {
    selectedCategories, setSelected,
    isServicesEnabled, isServicesNotAvailableForAllYears,
    hasServicesNotAvailableForSomeYearsTooltipBeenShown,
  } = input;
  const {loading, error, data, variables} = result;
  const {allProductsInput: {productClass}} = variables;
  if (productClass === null) {
    throw new Error('Product class cannot be null in product class selector');
  }
  let output: React.ReactElement<any> | null;
  if (loading === true) {
    output = null;
  } else if (error !== undefined) {
    output = null;
    console.error(error);
  } else if (data !== undefined) {
    output = (
      <SelectorContainer>
        <Selector
          allCategories={data.productCategorySelector}
          selectedCategories={selectedCategories}
          allowSectorToggle={true}
          setSelected={setSelected}
          productClass={productClass}
          isServicesEnabled={isServicesEnabled}
          isServicesNotAvailableForAllYears={isServicesNotAvailableForAllYears}
          hasServicesNotAvailableForSomeYearsTooltipBeenShown={hasServicesNotAvailableForSomeYearsTooltipBeenShown}
          servicesDisabledMessage={{useAlternateMessage: false}}
        />
      </SelectorContainer>
    );
  } else {
    output = null;
  }
  return output;
};

interface IProps {
  productClass: ProductClass;
  selectedCategories: string[];
  isServicesEnabled: boolean;
  isServicesNotAvailableForAllYears: boolean;
  hasServicesNotAvailableForSomeYearsTooltipBeenShown: boolean;
  setSelected: (ids: string[]) => void;
  Container: React.ComponentType<any>;
  extraDOMAttrOnRootNode: object | undefined;
}

export default (props: IProps) => {
  const {
    productClass, selectedCategories, setSelected,
    isServicesEnabled, isServicesNotAvailableForAllYears,
    hasServicesNotAvailableForSomeYearsTooltipBeenShown,
  } = props;
  const variables: Variables = {
    allProductsInput: {productClass, level: ProductLevel.section},
  };
  const renderProp = getRenderProp({
    selectedCategories, setSelected,
    isServicesEnabled, isServicesNotAvailableForAllYears,
    hasServicesNotAvailableForSomeYearsTooltipBeenShown,
  });
  return (
    <Query
      query={compiledQuery}
      variables={variables}
      children={renderProp}
    />
  );
};
