import React from 'react';
import {
  ITooltipDatum,
} from './otherTypes';
import Tooltip from './Tooltip';

interface IProps {
  hovered: string | undefined;
  highlighted: string | undefined;
  tooltipMap: Map<string, ITooltipDatum>;
  clearHighlightTooltip: () => void;
}

export default (props: IProps) => {
  const {
    tooltipMap, hovered, highlighted, clearHighlightTooltip,
  } = props;

  let highlightTooltip: React.ReactElement<any> | null;
  if (highlighted === undefined) {
    highlightTooltip = null;
  } else {
    const retrieved = tooltipMap.get(highlighted);
    if (retrieved === undefined) {
      highlightTooltip = null;
    } else {
      const {tooltipX, tooltipY, rows, title} = retrieved;
      highlightTooltip = (
        <Tooltip
          title={title} rows={rows}
          tooltipX={tooltipX} tooltipY={tooltipY}
          allowCloseTooltip={true}
          closeTooltip={clearHighlightTooltip}
        />
      );
    }
  }

  let hoverTooltip: React.ReactElement<any> | null;
  if (hovered === undefined) {
    hoverTooltip = null;
  } else if (hovered === highlighted) {
    hoverTooltip = null;
  } else {
    const retrieved = tooltipMap.get(hovered);
    if (retrieved === undefined) {
      hoverTooltip = null;
    } else {
      const {tooltipX, tooltipY, rows, title} = retrieved;
      hoverTooltip = (
        <Tooltip
          title={title} rows={rows}
          tooltipX={tooltipX} tooltipY={tooltipY}
          allowCloseTooltip={false}
        />
      );
    }
  }
  return (
    <>
      {highlightTooltip}
      {hoverTooltip}
    </>
  );
};
