import partition from 'lodash-es/partition';
import sum from 'lodash-es/sum';
import {
  IRect,
} from 'squarify';
import {
  ProductClass,
  ProductType,
  TradeDirection,
  TradeFlow,
} from '../../graphQL/graphQLTypes';
import { formatPercentage } from '../../numberFormatters';
import {
  failIfValidOrNonExhaustive,
  measuredCharacterHeight,
  measuredCharacterWidth,
  newHSColorMap,
  newSITCColorMap,
  referenceFontSize,
} from '../../Utils';
import addTextLayout from './addTextLayout';
import {
  FetchedProductDatum,
} from './graphQLTypes';
import {
  ITreeMapCell,
  TreeMapCSVProductRow,
} from './otherTypes';
import performLayout, {
  WithRect,
} from './performLayout';
import {
  computeGrossNetTradeValues,
  filterByMonetaryValues,
  maxCharacterHeightAtMinFontSize,
} from './transformUtils';

interface IInput {
  fetchResult: FetchedProductDatum[];
  width: number;
  height: number;
  selectedCategories: string[];
  tradeDirection: TradeDirection;
  tradeFlow: TradeFlow;
  productClass: ProductClass;
}
interface ITransformed {
  id: string;
  label: string;
  monetaryValue: number;
  topLevelParentId: string;
  type: ProductType;
  percentage: number;
}

const transform = (input: IInput): {transformed: ITreeMapCell[], csvData: TreeMapCSVProductRow[]} => {

  const {
    fetchResult, width, height, productClass, selectedCategories, tradeDirection, tradeFlow,
  } = input;
  const withComputedTradeValues = computeGrossNetTradeValues(fetchResult, tradeDirection, tradeFlow);
  const filteredByMonetaryValues = filterByMonetaryValues(withComputedTradeValues);
  const selectedCategoriesSet = new Set(selectedCategories);
  const filteredByCategories = filteredByMonetaryValues.filter(
    elem => selectedCategoriesSet.has(elem.product.topLevelParent.id),
  );
  const totalSum = sum(filteredByCategories.map(({monetaryValue}) => monetaryValue));

  const csvData: TreeMapCSVProductRow[] = [];
  const transformed: ITransformed[] = filteredByCategories.map(elem => {
    const {
      monetaryValue,
      product: {
        id, shortName, type, code,
        topLevelParent: {id: topLevelParentId, shortName: sectorName},
      },
    } = elem;
    const exportImportKey = tradeDirection === TradeDirection.export ? 'Gross Export' : 'Gross Import';
    csvData.push({
      Name: shortName,
      [exportImportKey]: monetaryValue,
      Share: (monetaryValue / totalSum) * 100,
      Code: code,
      Sector: sectorName,
    });
    return {
      id,
      label: shortName,
      monetaryValue,
      topLevelParentId,
      type,
      percentage: monetaryValue / totalSum,
    };
  });
  const [goods, services] = partition(transformed, ({type}) => type === ProductType.Goods);

  let withCellLayout: Array<WithRect<ITransformed>>;
  if (services.length > 0) {
    const servicesSum = sum(services.map(({monetaryValue}) => monetaryValue));
    const servicesShare = servicesSum / totalSum;

    const xSplitPoint = width * servicesShare;
    const servicesContainer: IRect = {
      x0: 0, y0: 0, x1: xSplitPoint, y1: height,
    };
    const goodsContainer: IRect = {
      x0: xSplitPoint, y0: 0, x1: width, y1: height,
    };
    const laidOutServices = performLayout(services, servicesContainer);
    const laidOutGoods = performLayout(goods, goodsContainer);
    withCellLayout = [...laidOutServices, ...laidOutGoods];
  } else {
    const goodsContainer = {
      x0: 0, y0: 0, x1: width, y1: height,
    };
    withCellLayout = performLayout(goods, goodsContainer);
  }

  const withTextLayout = withCellLayout.map(elem => addTextLayout({
    datum: elem,
    referenceFontSize, measuredCharacterHeight,
    measuredCharacterWidth, maxCharacterHeightAtMinFontSize,
    cellLabel: elem.label,
    cellValue: formatPercentage(elem.percentage),
  }));

  let colorMap: Map<string, string>;
  if (productClass === ProductClass.HS) {
    colorMap = newHSColorMap;
  } else if (productClass === ProductClass.SITC) {
    colorMap = newSITCColorMap;
  } else if (productClass === null) {
    throw new Error('Product class cannot be null');
  } else {
    failIfValidOrNonExhaustive(productClass, 'Invalid product class ' + productClass);
    // The following lines will never be executed:
    colorMap = new Map();
  }

  const withColor: ITreeMapCell[] = withTextLayout.map(({topLevelParentId, monetaryValue, ...rest}) => {
    const color = colorMap.get(topLevelParentId);
    if (color === undefined) {
      throw new Error('Cannot find color for top section ' + topLevelParentId);
    }
    const out: ITreeMapCell = {
      ...rest,
      color,
      value: monetaryValue,
    };
    return out;
  });
  return {transformed: withColor, csvData};
};

export default transform;
