import sum from 'lodash-es/sum';
import {
  TradeDirection,
  TradeFlow,
} from '../../graphQL/graphQLTypes';
import {
  TreeMapType,
} from '../../graphQL/tree/graphQLTypes';
import {
  failIfValidOrNonExhaustive,
} from '../../Utils';
import {
  computeGrossNetTradeValues,
  filterByMonetaryValues,
  filterBySelectedCategories,
} from '../newChart/transformUtils';
import {
  FetchedLocationDatum,
  FetchedProductDatum,
} from './graphQLTypes';
import {
  ISuccessResponse,
  Variables,
} from './index';

interface ITransformed {
  topLevelParentId: string;
  monetaryValue: number;
}

export interface IOtherInputs {
  selectedCategories: string[];
  tradeDirection: TradeDirection;
  tradeFlow: TradeFlow;
}

interface IInput {
  fetchResult: ISuccessResponse['graphTotal'];
  otherInputs: IOtherInputs;
  variables: Variables;
}

const transform = (input: IInput) => {
  const {
    fetchResult,
    otherInputs: {
      selectedCategories, tradeDirection, tradeFlow,
    },
    variables: {treeMapInput: {type}},
   } = input;

  let transformed: ITransformed[], denominator: number, totalServices: number | null | undefined;
  if (type === TreeMapType.CPY_C || type === TreeMapType.CCPY_CC) {
    const withComputedTradeValues = computeGrossNetTradeValues(
      fetchResult as FetchedProductDatum[], tradeDirection, tradeFlow,
    );
    const filteredByValue = filterByMonetaryValues(withComputedTradeValues);
    denominator = sum(filteredByValue.map(({monetaryValue}) => monetaryValue));
    totalServices = type === TreeMapType.CCPY_CC
      ? null : sum(filteredByValue.map(({monetaryValue, product: {topLevelParent}}) => {
      if (topLevelParent && topLevelParent.id &&
          (topLevelParent.id === 'product-HS-10' || topLevelParent.id === 'product-SITC-10')) {
        return monetaryValue;
      } else {
        return 0;
      }
    }));
    transformed = filteredByValue.map(elem => ({
      monetaryValue: elem.monetaryValue,
      topLevelParentId: elem.product.topLevelParent.id,
    }));
  } else if (type === TreeMapType.CPY_P || type === TreeMapType.CCPY_CP ||
              type === TreeMapType.CCY_C) {
    const withComputedTradeValues = computeGrossNetTradeValues(
      fetchResult as FetchedLocationDatum[], tradeDirection, tradeFlow,
    );
    const filteredByValue = filterByMonetaryValues(withComputedTradeValues);
    denominator = sum(filteredByValue.map(({monetaryValue}) => monetaryValue));
    transformed = filteredByValue.map(elem => ({
      monetaryValue: elem.monetaryValue,
      topLevelParentId: elem.location.topLevelParent.id,
    }));
    totalServices = type === TreeMapType.CCY_C ? null : undefined;
  } else {
    // The following lines will never be executed:
    transformed = [];
    denominator = 0;
    failIfValidOrNonExhaustive(type, 'Invalid tree map type' + type);
  }

  const filteredByCategory = filterBySelectedCategories(transformed, selectedCategories);
  const numerator = sum(filteredByCategory.map(({monetaryValue}) => monetaryValue));
  const totalGoods = totalServices ? denominator - totalServices : denominator;
  return {
    denominator, numerator, totalServices, totalGoods,
  };

};

export default transform;
