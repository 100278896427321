import {
  TradeDirection,
  TradeFlow,
} from '../../graphQL/graphQLTypes';
import {
  TreeMapType,
} from '../../graphQL/tree/graphQLTypes';
import {
  failIfValidOrNonExhaustive,
} from '../../Utils';
import {
  FetchedLocationDatum,
  FetchedProductDatum,
} from './graphQLTypes';
import {
  ISuccessResponse,
  Variables,
} from './index';
import {
  ITreeMapCell,
  TreeMapCSVProductRow,
  TreeMapCSVRow,
} from './otherTypes';
import transformLocation from './transformLocations';
import transformProduct from './transformProducts';

export interface IOtherInputs {
  width: number;
  height: number;
  selectedCategories: string[];
  tradeDirection: TradeDirection;
  tradeFlow: TradeFlow;
}

export interface ITransformInput {
  fetchedData: ISuccessResponse['chart'];
  variables: Variables;
  otherInputs: IOtherInputs;
}

const transform = (transformInput: ITransformInput):
  {transformed: ITreeMapCell[], csvData: TreeMapCSVRow[] | TreeMapCSVProductRow[]} => {
  const {
    fetchedData, variables, otherInputs,
  } = transformInput;

  const {
    width, height, selectedCategories, tradeDirection, tradeFlow,
  } = otherInputs;
  let transformed: ITreeMapCell[];
  let csvData: TreeMapCSVRow[] | TreeMapCSVProductRow[];
  const {treeMapInput} = variables;
  if (treeMapInput.type === TreeMapType.CPY_C ||
        treeMapInput.type === TreeMapType.CCPY_CC) {
    if (treeMapInput.productClass === null) {
      throw new Error('Product class cannot be null in CPY_C or CCPY_CC');
    }
    const transformedData = transformProduct({
      fetchResult: fetchedData as FetchedProductDatum[],
      width, height, selectedCategories,
      tradeDirection, tradeFlow,
      productClass: treeMapInput.productClass,
    });
    transformed = transformedData.transformed;
    csvData = transformedData.csvData;
  } else if (treeMapInput.type === TreeMapType.CPY_P ||
              treeMapInput.type === TreeMapType.CCY_C ||
              treeMapInput.type === TreeMapType.CCPY_CP) {
    const transformedData = transformLocation({
      fetchResult: fetchedData as FetchedLocationDatum[],
      width, height, selectedCategories,
      tradeDirection, tradeFlow,
    });
    transformed = transformedData.transformed;
    csvData = transformedData.csvData;
  } else {
    // The following lines will never be executed:
    transformed = undefined as any;
    csvData = undefined as any;
    failIfValidOrNonExhaustive(treeMapInput.type, 'Invalid tree map type' + TreeMapType);
  }
  return {transformed, csvData};
};

export default transform;
