import gql from 'graphql-tag';
import isEqual from 'lodash-es/isEqual';
import debounce from 'lodash/debounce';
import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Query,
} from 'react-apollo';
import ReactDOM from 'react-dom';
import Select from 'react-select';
import styled from 'styled-components';
import {
  LocationLevel,
  ProductClass,
  ProductLevel,
  TradeDirection,
  TradeFlow,
} from '../graphQL/graphQLTypes';
import {
  TreeMapType,
} from '../graphQL/tree/graphQLTypes';
import { useQueryParams } from '../routing';
import ErrorOverlay from '../sharedComponents/GraphError';
import ProductCategorySelector from '../sharedComponents/newCategorySelector/Product';
import RegionSelector from '../sharedComponents/newCategorySelector/Region';
import {
  SelectorContainer as ProductCategorySelectorContainer,
} from '../sharedComponents/newCategorySelector/sharedStyling';
import DataNotesDialog from '../sharedComponents/newDataNotes';
import ExportsPanel from '../sharedComponents/newExportsPanel';
import {
  Mode as ExportPanelMode,
} from '../sharedComponents/newExportsPanel/types';
import GraphShare, {
  DataNotesProps,
  TutorialModalProps,
} from '../sharedComponents/newGraphShare';
import TriggerDataDownloadQuery from '../sharedComponents/newGraphShare/TriggerDataDownloadQuery';
import PlayButton from '../sharedComponents/PlayButton';
import RadioSelector from '../sharedComponents/radioSelector';
import {
  IChoice,
} from '../sharedComponents/radioSelector/Utils';
import YearSelector, {
  TimelineType,
} from '../sharedComponents/timeline';
import {
  failIfValidOrNonExhaustive,
  INewDropdownOption,
  overlayPortalContainerId,
  Target,
} from '../Utils';
import ChartShownTitle from '../viz/ChartShownTitle';
import { parseQueryParamsIntoQueryStore } from '../viz/routingUtils';
import Tooltip from '../viz/Tooltip';
import {
  RightLowerControlContainer,
} from '../viz/TwoColumnVizControlsGrid';
import {
  clampYear,
  locationDetailLevelChoices,
  newExpandedProductDetailLevelChoices,
  newProductDetailLevelChoices,
} from '../viz/Utils';
import {
  ChartContainer,
  ChartHeader,
  ChartTitle,
  HighlightContainer,
  SpinnerContainer,
  TooltipsContainer,
  YearSelectorContainerWithPlayButton,
  YearSelectorInnerContainer,
} from '../viz/VizGrid';
import { vizSettingsPortalId } from '../viz/VizSettings';
import convertURLParamsToTreeMapInput, {
  ErrorCode,
} from './convertURLParamsToTreeMapInput';
import {
  getRenderProp as getDetailOverlayRenderProp,
  innerQuery as innerDetailOverlayQuery,
  Variables as DetailOverlayVariables,
} from './detailOverlay';
import determineDataWarnings from './determineDataWarnings';
import {
  getRenderProp as getDropdownRenderProp,
  innerQuery as innerDropdownQuery,
  Variables as DropdownVariables,
} from './dropdown';
import {
  getRenderProp as getGraphTotalRenderProp,
  innerQuery as innerGraphTotalQuery,
  Variables as GraphTotalVariables,
} from './graphTotal';
import GraphTotal from './graphTotal/GraphTotal';
import {
  getRenderProp as getHoverTooltipsRenderProp,
  innerQuery as innerHoverTooltipsQuery,
  Variables as HoverTooltipVariables,
} from './hoverTooltips';
import {
  ISuccessResponse,
} from './index';
import {
  getRenderProp as getChartRenderProp,
  outerQuery as chartQuery,
  Variables as ChartVariables,
} from './newChart';
import transform, {ITransformInput} from './newChart/transform';
import {
  combinedQuery as exportsQuery,
  getFetchedDataToGraphData as getFetchedDataToExportsGraphData,
  graphDataToPDF,
  graphDataToPNG,
  graphDataToSVG,
} from './newExports/transform';
import newGetTitle from './newGetTitle';
import treeURLTransformers from './URLTransformers';
import useNavigation from './useNavigation';
import usePlayButtonBehavior from './usePlayButtonBehaviorHook';
import usePrevious from './usePreviousHook';

//#region GraphQL-related
const combinedQuery = gql`
  query TreeMapCombined($treeMapInput: TreeMapInput!, $year: Int!) {
    ${innerDropdownQuery}
    ${innerHoverTooltipsQuery}
    ${innerDetailOverlayQuery}
    ${innerGraphTotalQuery}
  }
`;

type CombinedVariables =
  DropdownVariables & HoverTooltipVariables &
  DetailOverlayVariables & GraphTotalVariables;
//#endregion

//#region styled-components:
const Dropdown = styled(Select)`
  width: 100%;
`;
//#endregion

enum DialogType {
  None,
  Share,
  Exports,
  DataNotes,
  DataDownload,
}

type Props = ISuccessResponse & TutorialModalProps;

const Tree = (props: Props) => {
  const {isTutorialModalOpen, setIsTutorialModalOpen} = props;
  const queryParams = useQueryParams();
  const queryStore = parseQueryParamsIntoQueryStore(treeURLTransformers, queryParams);
  const {productClass, tradeDirection, tradeFlow} = queryStore;

  const [chartKey, setChartKey] = useState<string>(`tree-${window.innerWidth}-${window.innerHeight}`);

  useEffect(() => {
    const refreshOnResize = debounce(() => {
      setChartKey(`tree-${window.innerWidth}-${window.innerHeight}`);
    }, 300);
    window.addEventListener('resize', refreshOnResize);
    return () => {
      window.removeEventListener('resize', refreshOnResize);
    };
  }, []);

  const year = clampYear(productClass, queryStore.year);
  const urlParamsConversionResult = convertURLParamsToTreeMapInput({
    country: queryStore.country,
    product: queryStore.product,
    target: queryStore.target,
    partner: queryStore.partner,
    productClass,
  });

  //#region UI-related controls
  const [productLevel, setProductLevel] = useState<ProductLevel>(ProductLevel.fourDigit);
  const [locationLevel, setLocationLevel] = useState<LocationLevel>(LocationLevel.country);
  const [highlighted, setHighlighted] = useState<string | undefined>(undefined);
  const [hovered, setHovered] = useState<string | undefined>(undefined);
  const [detailed, setDetailed] = useState<string | undefined>(undefined);
  const [chartContainerSize, setChartContainerSize] = useState<{width: number, height: number} | undefined>(undefined);

  const resetHovered = () => setHovered(undefined);
  const clearHighlight = () => setHighlighted(undefined);
  const resetDetailed = () => setDetailed(undefined);

  const {changeYear} = useNavigation();
  //#endregion

  interface StopPlayDiffInput {
    country: number | undefined;
    product: number | undefined;
    partner: number | undefined;
    target: Target;
    tradeDirection: TradeDirection;
    tradeFlow: TradeFlow;
    productClass: ProductClass;
    productLevel: ProductLevel;
    locationLevel: LocationLevel;
  }

  const {
    stopPlaying, togglePlayButton, isPlaying,
  } = usePlayButtonBehavior<StopPlayDiffInput>({
    changeYear, productClass,
    currentYear: year,
    stopPlayDiffInput: {
      country: queryStore.country,
      product: queryStore.product,
      partner: queryStore.partner,
      target: queryStore.target,
      tradeDirection: queryStore.tradeDirection,
      tradeFlow, productClass,
      productLevel, locationLevel,
    },
  });

  //#region Initialize `selectedCategories`
  const {
    treeIndexHSProducts, treeIndexSITCProducts, treeIndexLocations,
  } = props;
  const allHSCategories = treeIndexHSProducts.filter(
    elem => elem.level === ProductLevel.section,
  ).map(elem => elem.id);
  const allSITCCategories = treeIndexSITCProducts.filter(
    elem => elem.level === ProductLevel.section,
  ).map(elem => elem.id);
  const allRegions = treeIndexLocations.filter(
    elem => elem.level === LocationLevel.region,
  ).map(elem => elem.id);

  let initialSelectedCategories: string[];
  let treeMapType: TreeMapType | undefined;
  if (urlParamsConversionResult.isValid === true) {
    const {info: {type}} = urlParamsConversionResult;
    if (type === TreeMapType.CPY_C || type === TreeMapType.CCPY_CC) {
      if (productClass === null) {
        throw new Error('Product class cannot be null');
      }
      if (productClass === ProductClass.HS) {
        initialSelectedCategories = allHSCategories;
      } else if (productClass === ProductClass.SITC) {
        initialSelectedCategories = allSITCCategories;
      } else {
        // These lines will never be executed:
        initialSelectedCategories = [];
        failIfValidOrNonExhaustive(productClass, 'Invalid productClass' + productClass);
      }
      treeMapType = type;
    } else if (type === TreeMapType.CPY_P ||
                type === TreeMapType.CCPY_CP ||
                type === TreeMapType.CCY_C) {
      initialSelectedCategories = allRegions;
      treeMapType = type;
    } else {
      // These lines will never be executed:
      initialSelectedCategories = [];
      treeMapType = undefined;
      failIfValidOrNonExhaustive(type, 'Invalid tree map type ' + type);
    }
  } else {
    initialSelectedCategories = [];
    treeMapType = undefined;
  }
  const [selectedCategories, setSelectedCategories] = useState<string[]>(initialSelectedCategories);
  const hiddenCategories =
    initialSelectedCategories.filter(category => !selectedCategories.find(selected => selected === category));
  //#endregion

  //#region Update `selectedCategories` in response to change in product class or graph type
  const prevProductClass = usePrevious(productClass);
  const prevTreeMapType = usePrevious(treeMapType);

  if (urlParamsConversionResult.isValid === true) {
    const hasChangedFromProductToLocation =
      (prevTreeMapType === TreeMapType.CPY_C ||
        prevTreeMapType === TreeMapType.CCPY_CC ||
        prevTreeMapType === undefined) &&
      (treeMapType === TreeMapType.CPY_P ||
        treeMapType === TreeMapType.CCY_C ||
        treeMapType === TreeMapType.CCPY_CP);
    const hasChangedFromLocationToProduct =
      (prevTreeMapType === TreeMapType.CPY_P ||
        prevTreeMapType === TreeMapType.CCY_C ||
        prevTreeMapType === TreeMapType.CCPY_CP ||
        prevTreeMapType === undefined) &&
      (treeMapType === TreeMapType.CPY_C ||
        treeMapType === TreeMapType.CCPY_CC);
    const hasChangedProductClass =
        (prevTreeMapType === TreeMapType.CPY_C ||
          prevTreeMapType === TreeMapType.CCPY_CC) &&
        (treeMapType === TreeMapType.CPY_C ||
          treeMapType === TreeMapType.CCPY_CC) &&
        (productClass !== prevProductClass) &&
        productClass !== undefined &&
        prevProductClass !== undefined;

    let newSelectedCategories: string[];
    if (hasChangedFromLocationToProduct) {
          if (productClass === ProductClass.HS) {
            newSelectedCategories = allHSCategories;
          } else if (productClass === ProductClass.SITC) {
            newSelectedCategories = allSITCCategories;
          } else if (productClass === null || productClass === undefined) {
            throw new Error('Product class must be valid for CPY_C or CCPY_CC tree map types');
          } else {
            // The following lines will never be executed:
            newSelectedCategories = [];
            failIfValidOrNonExhaustive(productClass, 'Invalid product class ' + productClass);
          }
        } else if (hasChangedFromProductToLocation) {
          newSelectedCategories = allRegions;
        } else if (hasChangedProductClass) {
          if (productClass === ProductClass.HS) {
            newSelectedCategories = allHSCategories;
          } else if (productClass === ProductClass.SITC) {
            newSelectedCategories = allSITCCategories;
          } else if (productClass === null || productClass === undefined) {
            throw new Error('Product class must be valid for CPY_C or CCPY_CC tree map types');
          } else {
            // The following lines will never be executed:
            newSelectedCategories = [];
            failIfValidOrNonExhaustive(productClass, 'Invalid product class ' + productClass);
          }
        } else {
          newSelectedCategories = selectedCategories;
        }
    if (!isEqual(newSelectedCategories, selectedCategories)) {
          setSelectedCategories(newSelectedCategories);
        }
  }
  //#endregion
  //#region chart container size measurement
  const chartContainerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (chartContainerRef && chartContainerRef.current) {
      const node = chartContainerRef.current;
      const width = node.clientWidth;
      const height = node.clientHeight;
      if (chartContainerSize === undefined ||
          chartContainerSize.width !== width || chartContainerSize.height !== height) {
      setChartContainerSize({width, height});
      }
    }
  }, [chartKey]);
  //#endregion

  //#region Exports related:
  const [dialogType, setDialogType] = useState<DialogType>(DialogType.None);
  const showShare = () => setDialogType(DialogType.Share);
  const showDownload = () => setDialogType(DialogType.Exports);
  const showDataNotes = () => setDialogType(DialogType.DataNotes);
  const showDataDownload = () => setDialogType(DialogType.DataDownload);
  const overlayPortalContainerNodeRef = useRef<HTMLElement | null>(null);
  useEffect(() => {
    const node = document.querySelector<HTMLElement>(`#${overlayPortalContainerId}`);
    overlayPortalContainerNodeRef.current = node;
  }, [overlayPortalContainerNodeRef.current]);
  const closeOverlay = () => setDialogType(DialogType.None);
  //#endregion

  //#region Render
  let chartElement: React.ReactElement<any> | null;
  let errorOverlay: React.ReactElement<any> | null;
  let detailLevelSelector: React.ReactElement<any> | null;
  let highlightDropdown: React.ReactElement<any> | null;
  let hoverTooltips: React.ReactElement<any> | null;
  let detailOverlay: React.ReactElement<any> | null;
  let graphTotal: React.ReactElement<any> | null;
  let categorySelector: React.ReactElement<any> | null;
  let dialog: React.ReactElement<any> | null;
  let share: React.ReactElement<any> | null;

  let graphTitle = '';
  if (urlParamsConversionResult.isValid === true) {
    errorOverlay = null;
    const {info} = urlParamsConversionResult;
    const treeMapInput: CombinedVariables['treeMapInput'] = {
      type: info.type,
      productClass,
      year,
      productLevel, locationLevel,
      location: info.location,
      product: info.product,
      partner: info.partner,
    };
    const combinedVariables: CombinedVariables = {
      treeMapInput,
      year,
    };

    const {
      isDataNotesWarningActive, dataNotes,
    } = determineDataWarnings({
      type: info.type,
      product: info.product,
      location: info.location,
      productClass,
      locations: treeIndexLocations,
      hsProducts: treeIndexHSProducts,
      sitcProducts: treeIndexSITCProducts,
    });
    const dataNotesProps: DataNotesProps = {
      isDataNotesWarningActive,
      launchDataNotes: showDataNotes,
    };

    share = (
      <GraphShare
        launchShare={showShare}
        launchExports={showDownload}
        showDataDownload={showDataDownload}
        dataNotesProps={dataNotesProps}
        isTutorialModalOpen={isTutorialModalOpen}
        setIsTutorialModalOpen={setIsTutorialModalOpen}
        closeDetailOverlay={resetDetailed}
      />
    );

    if (chartContainerSize === undefined) {
      chartElement = null;
      hoverTooltips = null;
      dialog = null;
    } else {
      const chartRenderProp = getChartRenderProp({
        width: chartContainerSize.width,
        height: chartContainerSize.height,
        selectedCategories,
        tradeDirection,
        tradeFlow,
        onCellClick: setDetailed,
        onMouseOverCell: setHovered,
        onMouseLeaveChart: resetHovered,
        highlighted,
      });
      const chartVariables: ChartVariables = {
        treeMapInput,
      };
      chartElement = (
        <Query
          key={chartKey}
          query={chartQuery}
          variables={chartVariables}
          children={chartRenderProp}
        />
      );
      const hoverTooltipsRenderProp = getHoverTooltipsRenderProp({
        width: chartContainerSize.width,
        height: chartContainerSize.height,
        selectedCategories,
        tradeDirection,
        tradeFlow,
        hovered, highlighted,
        clearHighlightTooltip: clearHighlight,
      });
      hoverTooltips = (
        <Query
          query={combinedQuery}
          variables={combinedVariables}
          children={hoverTooltipsRenderProp}
        />
      );

      const fetchedDataToGraphData = getFetchedDataToExportsGraphData({
        width: chartContainerSize.width,
        height: chartContainerSize.height,
        selectedCategories,
        tradeDirection,
        tradeFlow,
      });
      const otherInputs = {
        width: chartContainerSize.width,
        height: chartContainerSize.height,
      };

      graphTitle = newGetTitle({
        inputFromURLParams: {
          type: info.type,
          location: info.location,
          product: info.product,
          partner: info.partner,
          year, tradeDirection,
        },
        locationsData: treeIndexLocations,
        hsProductsData: treeIndexHSProducts,
        sitcProductsData: treeIndexSITCProducts,
      });

      //#region Exports sharing panel:
      const overlayPortalContainerNode = overlayPortalContainerNodeRef.current;
      if (overlayPortalContainerNode !== null && dialogType !== DialogType.None) {
        if (dialogType === DialogType.Share ||
              dialogType === DialogType.Exports) {

          let initialMode: ExportPanelMode;
          if (dialogType === DialogType.Share) {
            initialMode = ExportPanelMode.Share;
          } else if (dialogType === DialogType.Exports) {
            initialMode = ExportPanelMode.Export;
          } else {
            // The following lines will never be executed:
            initialMode = undefined as any;
            failIfValidOrNonExhaustive(dialogType, 'Invalid dialog status type' + dialogType);
          }

          dialog = ReactDOM.createPortal(
            (
              <ExportsPanel
                initialMode={initialMode}
                closeOverlay={closeOverlay}
                variables={chartVariables}
                query={exportsQuery}
                otherInputs={otherInputs}
                fetchedDataToGraphData={fetchedDataToGraphData}
                svgConfig={{
                  isEnabled: true,
                  graphDataToDownloadableData: graphDataToSVG,
                }}
                pngConfig={{
                  isEnabled: true,
                  graphDataToDownloadableData: graphDataToPNG,
                }}
                pdfConfig={{
                  isEnabled: true,
                  graphDataToDownloadableData: graphDataToPDF,
                }}
                graphTitle={graphTitle}
              />
            ),
            overlayPortalContainerNode,
          );
        } else if (dialogType === DialogType.DataNotes) {
          dialog = ReactDOM.createPortal(
            (<DataNotesDialog dataIssues={dataNotes} closeOverlay={closeOverlay}/>),
            overlayPortalContainerNode,
          );
        }  else if (dialogType === DialogType.DataDownload) {
          const generateCsvData = (input: ITransformInput) => {
            const fetchedData = (input.fetchedData as any).chart as ITransformInput['fetchedData'];
            const {csvData} = transform({...input, fetchedData});
            return csvData;
          };
          dialog = (
            <TriggerDataDownloadQuery
              variables={chartVariables}
              query={exportsQuery}
              otherInputs={{selectedCategories, tradeDirection, tradeFlow, width: 0, height: 0}}
              graphTitle={graphTitle}
              closeOverlay={closeOverlay}
              fetchedDataToCsvData={generateCsvData}
            />
          );
        } else {
          // The following lines will never be executed:
          dialog = null;
          failIfValidOrNonExhaustive(dialogType, 'Invalid dialog type ' + dialogType) ;
        }
      } else {
        dialog = null;
      }
      //#endregion

    }

    const detailOverlayRenderProp = getDetailOverlayRenderProp({
      hideOverlay: resetDetailed,
      detailed,
      tradeDirection,
      tradeFlow,
      year,
    });
    detailOverlay = (
      <Query
        query={combinedQuery}
        variables={combinedVariables}
        children={detailOverlayRenderProp}
      />
    );
    const updateHighlightFromDropdown = (selection: INewDropdownOption | null) => {
      if (selection === null) {
        setHighlighted(undefined);
      } else {
        const {value} = selection;
        setHighlighted(value);
      }
    };
    const dropdownRenderProp = getDropdownRenderProp({
      selectedCategories,
      tradeDirection,
      tradeFlow,
      highlighted,
      onChange: updateHighlightFromDropdown,
      DropdownComponent: Dropdown as any,
      DropdownContainerComponent: RightLowerControlContainer,
    });
    highlightDropdown = (
      <Query
        query={combinedQuery}
        variables={combinedVariables}
        children={dropdownRenderProp}
      />
    );

    const graphTotalRenderProp = getGraphTotalRenderProp({
      selectedCategories,
      tradeDirection,
      tradeFlow,
      hiddenCategories,
    });
    graphTotal = (
      <Query
        query={combinedQuery}
        variables={combinedVariables}
        children={graphTotalRenderProp}
      />
    );
    if (info.type === TreeMapType.CPY_C || info.type === TreeMapType.CCPY_CC) {

      let choices: Array<IChoice<ProductLevel>>;
      if (productClass === ProductClass.HS) {
        choices = newExpandedProductDetailLevelChoices;
      } else if (productClass === ProductClass.SITC) {
        choices = newProductDetailLevelChoices;
      } else if (productClass === null) {
        throw new Error('Product class cannot be null in CPY_C or CCPY_CP');
      } else {
        // The following lines will never be executed:
        choices = [];
        failIfValidOrNonExhaustive(productClass, 'Invalid product class ' + productClass);
      }

      detailLevelSelector = (
        <RadioSelector
          tooltipText={__lexiconText('applicationWide.productDetailLevelSelector.tooltipText')}
          mainLabel={__lexiconText('applicationWide.productDetailLevelSelector.mainLabel')}
          choices={choices}
          selected={productLevel}
          onClick={(level: ProductLevel) => setProductLevel(level)}
        />
      );

      const isServicesEnabled = (info.type !== TreeMapType.CCPY_CC);
      categorySelector = (
        <ProductCategorySelector
          productClass={productClass}
          selectedCategories={selectedCategories}
          isServicesEnabled={isServicesEnabled}
          isServicesNotAvailableForAllYears={false}
          hasServicesNotAvailableForSomeYearsTooltipBeenShown={false}
          setSelected={setSelectedCategories}
          Container={ProductCategorySelectorContainer}
          extraDOMAttrOnRootNode={undefined}
        />
      );
    } else if (info.type === TreeMapType.CPY_P ||
                info.type === TreeMapType.CCY_C ||
                info.type === TreeMapType.CCPY_CP) {
      detailLevelSelector = (
        <RadioSelector
          tooltipText={__lexiconText('applicationWide.countryDetailLevelSelector.tooltipText')}
          mainLabel={__lexiconText('applicationWide.countryDetailLevelSelector.mainLabel')}
          choices={locationDetailLevelChoices}
          selected={locationLevel}
          onClick={(level: LocationLevel) => setLocationLevel(level)}
        />
      );
      categorySelector = (
        <RegionSelector
          selectedCategories={selectedCategories}
          setSelected={setSelectedCategories}
        />
      );
    } else {
      // The following lines will never be executed:
      detailLevelSelector = null;
      categorySelector = null;
      failIfValidOrNonExhaustive(info.type, 'Invalid tree map type ' + info.type);
    }

  } else {
    chartElement = null;
    const {errorCode} = urlParamsConversionResult;
    let message: string;
    if (errorCode === ErrorCode.PickCountryOrProduct) {
      message = __lexiconText('error.productMode');
    } else if (errorCode === ErrorCode.PickCountryOrPartner) {
      message = __lexiconText('error.partnerMode');
    } else {
      // The following lines will never be executed:
      message = '';
      failIfValidOrNonExhaustive(errorCode, 'Invalid error code ' + errorCode);
    }
    errorOverlay = (
      <ErrorOverlay message={message}/>
    );
    detailLevelSelector = null;
    highlightDropdown = null;
    hoverTooltips = null;
    detailOverlay = null;
    graphTotal = (
      <GraphTotal
        numerator={0}
        denominator={0}
        totalGoods={0}
        totalServices={undefined}
        hiddenCategories={hiddenCategories}
        selectedCategories={selectedCategories}
      />
    );
    categorySelector = null;
    dialog = null;

    const dataNotesProps: DataNotesProps = {
      isDataNotesWarningActive: false,
      launchDataNotes: showDataNotes,
    };
    share = (
      <GraphShare
        launchShare={null}
        launchExports={null}
        showDataDownload={null}
        dataNotesProps={dataNotesProps}
        isTutorialModalOpen={isTutorialModalOpen}
        setIsTutorialModalOpen={setIsTutorialModalOpen}
        closeDetailOverlay={resetDetailed}
      />
    );
    const overlayPortalContainerNode = overlayPortalContainerNodeRef.current;
    if (overlayPortalContainerNode !== null && dialogType === DialogType.DataNotes) {
      dialog = ReactDOM.createPortal(
        (<DataNotesDialog dataIssues={[]} closeOverlay={closeOverlay}/>),
        overlayPortalContainerNode,
      );
    }
  }

  const vizSettingsNodeRef = document.querySelector<HTMLElement>(`#${vizSettingsPortalId}`);

  let vizSettings: React.ReactElement<any> | null;
  if (vizSettingsNodeRef) {
    vizSettings = ReactDOM.createPortal((
      <>
        {detailLevelSelector}
      </>
    ), vizSettingsNodeRef);
  } else {
    vizSettings = null;
  }

  const changeTimelineYear = (inputYear: number) => {
    stopPlaying();
    changeYear(inputYear);
  };

  return (
    <>
      <ChartHeader>
        <ChartTitle>
          <Tooltip
            explanation={graphTitle}
            title={graphTitle}
          />
        </ChartTitle>
        <ChartShownTitle
          text={graphTotal}
        />
      </ChartHeader>
      <ChartContainer ref={chartContainerRef}>
        {chartElement}
      </ChartContainer>
      <TooltipsContainer>
        {hoverTooltips}
      </TooltipsContainer>
      <SpinnerContainer>
        {errorOverlay}
      </SpinnerContainer>
      {detailOverlay}
      {categorySelector}
      <HighlightContainer>
        {highlightDropdown}
      </HighlightContainer>
      <YearSelectorContainerWithPlayButton>
        <PlayButton isPlaying={isPlaying} onClick={togglePlayButton} size={30}/>
        <YearSelectorInnerContainer>
          <YearSelector
            productClass={productClass} year={year}
            type={TimelineType.SingleYear}
            onYearChange={changeTimelineYear}
          />
        </YearSelectorInnerContainer>
      </YearSelectorContainerWithPlayButton>
      {vizSettings}
      {share}
      {dialog}
    </>
  );
  //#endregion
};

export default Tree;
