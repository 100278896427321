import partition from 'lodash-es/partition';
import sum from 'lodash-es/sum';
import {
  IRect,
} from 'squarify';
import {
  ProductClass,
  ProductType,
  TradeDirection,
  TradeFlow,
} from '../../graphQL/graphQLTypes';
import {
  formatPercentage,
  formatTradeValue,
} from '../../numberFormatters';
import {
  failIfValidOrNonExhaustive,
  newGetDisplayedProductCode,
  newHSColorMap,
  newSITCColorMap,
} from '../../Utils';
import {
  IDetailOverlayRow as IRow,
} from '../../viz/Utils';
import performLayout, {
  WithRect,
} from '../newChart/performLayout';
import {
  computeGrossNetTradeValues,
  filterByMonetaryValues,
  filterBySelectedCategories,
} from '../newChart/transformUtils';
import getTradeLabel from './getTradeLabel';
import {
  FetchedProductDatum,
} from './graphQLTypes';
import {
  ITooltipDatum,
} from './otherTypes';

interface IInput {
  fetchResult: FetchedProductDatum[];
  width: number;
  height: number;
  selectedCategories: string[];
  tradeDirection: TradeDirection;
  tradeFlow: TradeFlow;
  productClass: ProductClass;
}

interface ITransformed {
  id: string;
  title: string;
  monetaryValue: number;
  type: ProductType;
  rows: IRow[];
  topLevelParentId: string;
}
const transform = (input: IInput): Map<string, ITooltipDatum> => {
  const {
    fetchResult, width, height, productClass,
    selectedCategories, tradeDirection, tradeFlow,
  } = input;
  const withComputedTradeValues = computeGrossNetTradeValues(fetchResult, tradeDirection, tradeFlow);
  const filtered = filterByMonetaryValues(withComputedTradeValues);
  const totalSum = sum(filtered.map(({monetaryValue}) => monetaryValue));

  let colorMap: Map<string, string>;
  if (productClass === ProductClass.HS) {
    colorMap = newHSColorMap;
  } else if (productClass === ProductClass.SITC) {
    colorMap = newSITCColorMap;
  } else {
    failIfValidOrNonExhaustive(productClass, 'Invalid product class ' + productClass);
    // The following lines will never be executed:
    colorMap = undefined as any;
  }

  const transformed = filtered.map(elem => {
    const {
      product: {
        id, shortName, type, code, level,
        topLevelParent: {id: topLevelParentId},
      },
      monetaryValue,
    } = elem;

    const codeInfo: IRow = {
      label: __lexiconText('applicationWide.code'),
      value: newGetDisplayedProductCode(code, productClass, level),
    };
    const tradeInfo: IRow = {
      label: getTradeLabel(tradeDirection, tradeFlow),
      value: formatTradeValue(monetaryValue),
    };
    const percentage = monetaryValue / totalSum;
    const shareInfo: IRow = {
      label: __lexiconText('applicationWide.share'),
      value: formatPercentage(percentage),
    };

    const rows = [codeInfo, tradeInfo, shareInfo];

    const color = colorMap.get(topLevelParentId);
    if (color === undefined) {
      throw new Error('Cannot retrieve color for section ' + topLevelParentId);
    }
    const out: ITransformed = {
      id,
      title: shortName,
      monetaryValue,
      type,
      rows,
      topLevelParentId,
    };
    return out;
  });

  const filteredByCategories = filterBySelectedCategories(transformed, selectedCategories);
  const [goods, services] = partition(filteredByCategories, ({type}) => type === ProductType.Goods);

  let withCellLayout: Array<WithRect<ITransformed>>;
  if (services.length > 0) {
    const servicesSum = sum(services.map(({monetaryValue}) => monetaryValue));
    const servicesShare = servicesSum / totalSum;

    const xSplitPoint = width * servicesShare;
    const servicesContainer: IRect = {
      x0: 0, y0: 0, x1: xSplitPoint, y1: height,
    };
    const goodsContainer: IRect = {
      x0: xSplitPoint, y0: 0, x1: width, y1: height,
    };
    const laidOutServices = performLayout(services, servicesContainer);
    const laidOutGoods = performLayout(goods, goodsContainer);
    withCellLayout = [...laidOutServices, ...laidOutGoods];
  } else {
    const goodsContainer = {
      x0: 0, y0: 0, x1: width, y1: height,
    };
    withCellLayout = performLayout(goods, goodsContainer);
  }
  const mapPrecursor = withCellLayout.map(elem => {
    const {
      x0, y0, x1, y1, id,
      monetaryValue: _unused,
      ...rest
    } = elem;
    const out: ITooltipDatum = {
      ...rest,
      id,
      tooltipX: (x0 + x1) / 2,
      tooltipY: y0,
    };
    return [id, out] as [string, ITooltipDatum];
  });
  return new Map(mapPrecursor);
};

export default transform;
