import {
  extractFromStringProductId,
} from '../graphQL/dataFetchers/Utils';
import {
  ProductClass,
  TradeDirection,
} from '../graphQL/graphQLTypes';
import {
  TreeMapType,
} from '../graphQL/tree/graphQLTypes';
import {
  failIfValidOrNonExhaustive,
} from '../Utils';

interface IEntityDatum {
  id: string;
  shortName: string;
}

interface IFromURLParams {
  type: TreeMapType;
  location: string | null;
  product: string | null;
  partner: string | null;
  year: number;
  tradeDirection: TradeDirection;
}

interface IInput<T extends IEntityDatum, U extends IEntityDatum> {
  inputFromURLParams: IFromURLParams;
  locationsData: T[];
  hsProductsData: U[];
  sitcProductsData: U[];
}

export default <T extends IEntityDatum, U extends IEntityDatum>(input: IInput<T, U>): string => {
  const {inputFromURLParams: urlParams, locationsData, hsProductsData, sitcProductsData} = input;

  const findLocationInfo = (id: string): T => {
    const foundLocation = locationsData.find(elem => elem.id === id);
    if (foundLocation === undefined) {
      throw new Error('Cannot find location data for ID ' + location);
    }
    return foundLocation;
  };

  const findProductInfo = (id: string): U => {
    const {productClass} = extractFromStringProductId(id);
    let productsData: typeof hsProductsData;
    if (productClass === ProductClass.HS) {
      productsData = hsProductsData;
    } else if (productClass === ProductClass.SITC) {
      productsData = sitcProductsData;
    } else {
      failIfValidOrNonExhaustive(productClass , 'Invalid product class ' + productClass);
      // The following lines will never be executed:
      productsData = [];
    }
    const foundProduct = productsData.find(elem => elem.id === id);
    if (foundProduct === undefined) {
      throw new Error('Cannot find product data for ID ' + id);
    }
    return foundProduct;
  };

  const {type, tradeDirection, location, product, partner, year} = urlParams;
  let phraseBeforeYear: string;
  const tradeWord = (tradeDirection === TradeDirection.export) ? 'export' : 'import';
  const preposition = (tradeDirection === TradeDirection.export) ? 'to' : 'from';
  if (type === TreeMapType.CPY_C) {
    if (location === null) {
      throw new Error('location must not be null in CPY_C');
    }
    const locationInfo = findLocationInfo(location);
    phraseBeforeYear = `What did ${locationInfo.shortName} ${tradeWord}`;
  } else if (type === TreeMapType.CPY_P) {
    if (product === null) {
      throw new Error('product must not be null in CPY_P');
    }
    const productInfo = findProductInfo(product);
    const tradeWordPastTense = (tradeDirection === TradeDirection.export) ? 'exported' : 'imported';
    phraseBeforeYear = `Who ${tradeWordPastTense} ${productInfo.shortName}`;
  } else if (type === TreeMapType.CCY_C) {
    if (location === null) {
      throw new Error('location must not be null in CCY_C');
    }
    const locationInfo = findLocationInfo(location);
    phraseBeforeYear = `Where did ${locationInfo.shortName} ${tradeWord} ${preposition}`;
  } else if (type === TreeMapType.CCPY_CP) {
    if (location === null || product === null) {
      throw new Error('Both location and product be not null in CCPY_CP');
    }
    const locationInfo = findLocationInfo(location);
    const productInfo = findProductInfo(product);
    phraseBeforeYear = `Where did ${locationInfo.shortName} ${tradeWord} ${productInfo.shortName} ${preposition}`;
  } else if (type === TreeMapType.CCPY_CC) {
    if (location === null || partner === null) {
      throw new Error('Both location and partner must be not null in CCPY_CC');
    }
    const locationInfo = findLocationInfo(location);
    const partnerInfo = findLocationInfo(partner);
    phraseBeforeYear = `What did ${locationInfo.shortName} ${tradeWord} ${preposition} ${partnerInfo.shortName}`;
  } else {
    failIfValidOrNonExhaustive(type, 'Invalid tree map type ' + type);
    // The following lines will never be executed:
    phraseBeforeYear = '';
  }
  const yearPhrase = `in ${year}`;
  return `${phraseBeforeYear} ${yearPhrase}?`;
};
