import isEqual from 'lodash-es/isEqual';
import range from 'lodash-es/range';
import {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  ProductClass,
} from '../graphQL/graphQLTypes';
import {
  earliestHSYear,
  earliestSITCYear,
  failIfValidOrNonExhaustive,
  latestYear,
} from '../Utils';
import {
  playButtonInterval,
} from '../viz/Utils';
import usePrevious from './usePreviousHook';

// Get years to iterate over during play:
const getTimelineYears = (firstYear: number, lastYear: number, currentYear: number) => {
  const yearsAfter = range(currentYear + 1, lastYear + 1);
  const yearsBefore = range(firstYear, currentYear);
  // `reverse` because we'll `pop` off years at the end of the array:
  return [...yearsAfter, ...yearsBefore].reverse();
};

interface IInput<StopPlaybackDiffInput> {
  productClass: ProductClass;
  changeYear: (year: number) => void;
  currentYear: number;
  // if the timeine is playing, it will stop when one of these properties change:
  stopPlayDiffInput: StopPlaybackDiffInput;
}

export default <StopPlaybackDiffInput>(input: IInput<StopPlaybackDiffInput>) => {
  const {
    changeYear, currentYear, productClass,
    stopPlayDiffInput,
  } = input;
  //#region Play button-related
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const playTimerRef = useRef<number | undefined>(undefined);
  const cancelPlayTimer = () => {
    const {current: timer} = playTimerRef;
    if (timer !== undefined) {
      clearInterval(timer);
    }
  };

  const stopPlaying = () => {
    cancelPlayTimer();
    const newIsPlaying = false;
    if (newIsPlaying !== isPlaying) {
      setIsPlaying(newIsPlaying);
    }
  };
  const togglePlayButton = () => {
    const newIsPlaying = !isPlaying;
    if (newIsPlaying !== isPlaying) {
      setIsPlaying(newIsPlaying);
    }
  };

  const prevIsPlaying = usePrevious(isPlaying);

  const prevStopPlayDiffInput = usePrevious<StopPlaybackDiffInput>(stopPlayDiffInput);
  if (!isEqual(stopPlayDiffInput, prevStopPlayDiffInput)) {
    stopPlaying();
  }

  useEffect(() => {
    // If `prevIsPlaying` is false, we're on the first render and the component
    // will not be in "play" mode:
    if ((prevIsPlaying === false || prevIsPlaying === undefined) && isPlaying === true) {
      let earliestYear: number;
      if (productClass === ProductClass.HS) {
        earliestYear = earliestHSYear;
      } else if (productClass === ProductClass.SITC) {
        earliestYear = earliestSITCYear;
      } else {
        failIfValidOrNonExhaustive(productClass, 'Invalid product class ' + productClass);
        // The following lines will never be executed:
        earliestYear = 0;
      }
      const playedYears = getTimelineYears(earliestYear, latestYear, currentYear);
      const playNextYear = () => {
        const nextYear = playedYears.pop();
        if (nextYear === undefined) {
          stopPlaying();
        } else {
          changeYear(nextYear);
          if (playedYears.length === 0) {
            stopPlaying();
          }
        }
      };
      playNextYear();
      playTimerRef.current = setInterval(playNextYear, playButtonInterval);
      return cancelPlayTimer;
    } else if (prevIsPlaying === true && isPlaying === false) {
      cancelPlayTimer();
    }
  }, [isPlaying]);
  //#endregion

  return {
    togglePlayButton, stopPlaying, isPlaying,
  };
};
