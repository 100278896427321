import gql from 'graphql-tag';
import React from 'react';
import {
  Query,
  QueryResult,
} from 'react-apollo';
import {
  AllLocationsInput,
  LocationLevel,
} from '../../graphQL/graphQLTypes';
import {
  FetchedLocationDatum,
} from './graphQLTypes';
import Selector from './RegionSelector';

interface Variables {
  allLocationsInput: AllLocationsInput;
}

const innerQuery = `
  regionSelector: allLocations(input: $allLocationsInput) {
    id
    shortName
  }
`;

const compiledQuery = gql`
  query RegionSelector($allLocationsInput: AllLocationsInput!) {
    ${innerQuery}
  }
`;

interface ISuccessResponse {
  regionSelector: FetchedLocationDatum[];
}

type Result = QueryResult<ISuccessResponse, Variables>;

interface IRenderPropInput {
  selectedCategories: string[];
  setSelected: (selectedCategories: string[]) => void;
}

const getRenderProp = (input: IRenderPropInput) => (result: Result) => {
  const {
    selectedCategories, setSelected,
  } = input;
  const {loading, error, data} = result;
  let output: React.ReactElement<any> | null;
  if (loading === true) {
    output = null;
  } else if (error !== undefined) {
    output = null;
    console.error(error);
  } else if (data !== undefined) {
    output = (
      <Selector
        allCategories={data.regionSelector}
        selectedCategories={selectedCategories}
        setSelected={setSelected}
      />
    );
  } else {
    output = null;
  }
  return output;
};

interface IProps {
  selectedCategories: string[];
  setSelected: (ids: string[]) => void;
}

export default (props: IProps) => {
  const {
    selectedCategories, setSelected,
  } = props;
  const variables: Variables = {
    allLocationsInput: {level: LocationLevel.region},
  };
  const renderProp = getRenderProp({
    selectedCategories, setSelected,
  });
  return (
    <Query
      query={compiledQuery}
      variables={variables}
      children={renderProp}
    />
  );
};
